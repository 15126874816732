import React from 'react';

function Gaming() {
    return (
    <div className="container text-center">
        <h2>I am also a gamer and I like to play video games!</h2>
        <p>I am also a member of Ilmenau eSports team in TU Ilmenau for CS2 and Valorant. Here are the links to my gaming stuff!</p>
        <a className="btn" href="https://www.dotabuff.com/players/395370670"><i className="fas fa-gamepad"></i>Dotabuff: MeyraMax</a>
        <a className="btn" href="https://steamcommunity.com/id/sasadkasokcasokmpo/"><i className="fab fa-steam"></i>Steam: MeyraMax</a>
        <a className="btn" href="https://discord.com/users/297442450623037441"><i className="fab fa-discord"></i>Discord: meyramax</a>
        <a className="btn" href="https://www.instagram.com/ilmenau_esport/"><i className="fab fa-instagram"></i>Instagram: Ilmenau eSport</a>
        <a className="btn" href="https://tracker.gg/valorant/profile/riot/MeyraMax%23xdxd/overview"><i className="fas fa-crosshairs"></i>Valorant: MeyraMax #xDxD</a>
        <a className="btn" href="https://tracker.gg/valorant/profile/riot/butterfly%238080/overview"><i className="fas fa-crosshairs"></i>Valorant (smurf): butterfly #8080</a>
        <a className="btn" href="https://www.twitch.tv/meyramax"><i className="fab fa-twitch"></i>Twitch: MeyraMax</a>
        <p>Other stuff:</p>
        <a className="btn" href="https://myanimelist.net/animelist/MeyraMax"><i className="fas fa-tv"></i>Myanimelist: MeyraMax</a>
    </div>
    );
}

export default Gaming;
