import React from 'react';

function Books() {
  return (
    <div className="container text-center">
      <h2>These are the books that I have read and would recommend:</h2>
      <a className="btn2" href="https://www.amazon.de/-/en/Anders-Ericsson-ebook/dp/B011H56MKS"><i className="fas fa-book"></i>Peak: Secrets from the New Science of Expertise </a>
      <a className="btn2" href="https://www.amazon.de/-/en/Robert-Greene/dp/0140280197"><i className="fas fa-book"></i>48 Laws of Power by Robert Greene  </a>
      <a className="btn2" href="https://www.amazon.de/Atomic-Habits-Proven-Build-Break/dp/1847941834"><i className="fas fa-book"></i>Atomic Habits by James Clear </a>
      <h2>These ones I have started but not yet completed but I recommend as well:</h2>
      <a className="btn2" href="https://www.amazon.de/-/en/John-Lees/dp/1292463309"><i className="fas fa-book"></i>How to get a job you love (Find a job worth getting up for) by John Lees</a>
      <a className="btn2" href="https://www.amazon.de/-/en/Daniel-Keyes/dp/0156030306"><i className="fas fa-book"></i>Flowers for Algernon by Daniel Keyes</a>
      <a className="btn2" href="https://www.amazon.de/-/en/Michiko-Aoyama/dp/0857529129"><i className="fas fa-book"></i>What you are looking for is in the library by Michiko Aoyama</a>
      <a className="btn2" href="https://www.amazon.de/Art-War-Sun-Tzu/dp/1721195092"><i className="fas fa-book"></i>The Art of War by Sun Tzu</a>
      <a className="btn2" href="https://www.amazon.de/Your-Pocket-Therapist-ver%C3%A4ndere-Gesundheit/dp/349206485X"><i className="fas fa-book"></i>Your pocket therapist by Dr Annie Zimmerman</a>
    </div>
  );
}

export default Books;