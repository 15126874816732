import React from 'react';

function Certifications() {
    return (
        <div className="container text-center">
            <h2>Welcome to my portfolio!</h2>
            <p>Here are the list of my certifications:</p>
            <a className="btn2" href="https://www.linkedin.com/learning/certificates/aa96278558f910c703cbe95f6bc17dbfe239c404f82fa31a86e1a2687ee23d9c"><i className="fas fa-award"></i>Career Essentials in Software Development by Microsoft and LinkedIn</a>
            <a className="btn2" href="https://www.linkedin.com/in/muhammad-basit-zaheer/"><i className="fab fa-linkedin"></i>Learning Docker (LinkedIn) </a>
            <a className="btn2" href="https://coursera.org/share/c6780f8dc7972828454321f44cdb9c2d"><i className="fab fa-github"></i>Getting Started with Git and GitHub (Coursera)</a>
            <a className="btn2" href="https://coursera.org/share/88956b73021a12cd7c4149cfbffd8d09"><i className="fab fa-python"></i>Python - Data Structures (Coursera)</a>
            <a className="btn2" href="https://coursera.org/share/ce30da779d1f6d8d8b1cd8c707586fe6"><i className="fab fa-python"></i>Python - Basics (Coursera)</a>
            <a className="btn2" href="https://coursera.org/share/46e53b2435f279d8735a2ecad9984401"><i className="fas fa-code"></i>Programming for Everybody (Getting Started with Python)</a>
        </div>
    );
}

export default Certifications;